<template>
  <div
    @dragover.prevent
    @drop.prevent
    key="mainlayout1"
  >
    <span
      :style="appStyle"
      class="bg"
    />
    <v-app-bar
      app
      clipped-right
      :color="themeController.bannerColor"
      :class="[themeController.isFramed ? 'primary' : 'inherit']"
      dense
      height=60
    >
      <audit-panel v-if="auditController.dataAudit" />
      <div
        v-else
        class="flex-grow-1 remove-basis d-flex"
      >
        <v-toolbar-title
          v-if="currentModule"
          class="ml-2"
        >
          <component
            v-if="currentModule.componentName"
            :document="currentModule.data"
            :options="currentModule.options"
            v-bind:is="currentModule.componentName"
          />
          <div
            v-else
            class="d-flex flex-row align-center"
          >
            <v-icon
              v-if="currentModule.icon"
              class="mr-2 inherit-color"
            >{{$icon(currentModule.icon)}}</v-icon>
            <span class="font-weight-bold">{{$tc($store.getters.module.name)}}</span>
          </div>
        </v-toolbar-title>
        <v-spacer />
        <v-tooltip
          bottom
          open-delay="200"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <system-status-panel />
            </div>
          </template>
          <span>{{$t('t.Alerts')}}</span>
        </v-tooltip>
      </div>
      <div
        class="d-flex align-center"
        v-if="!auditController.dataAudit"
      >
        <main-presets />
        <main-action-button />
      </div>
      <div class="d-flex flex-grow-1 align-center justify-end remove-basis">
        <mails v-if="$store.getters.currentUserHasPermission('Messages') && !auditController.dataAudit" />
        <notif v-if="!auditController.dataAudit" />
        <recents-menu v-if="!auditController.dataAudit" />
        <search-field :placeholder="$t('t.HitEnterToSearch')" />
        <current-user />
      </div>
    </v-app-bar>
    <v-navigation-drawer
      :color="themeController.leftDrawerColor"
      v-model="menuDrawer"
      app
      mini-variant-width="48"
      :mini-variant="menuDrawerIsMini"
      class="navigation"
    >
      <template v-slot:prepend>
        <v-icon
          class="full-width mt-4"
          color="primary"
          size=30
        >{{$icon('i.COTCollection')}}</v-icon>
      </template>
      <v-list flat>
        <v-list-item-group
          color='primary'
          mandatory
          :value="$route.fullPath"
        >
          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            link
            @click.stop="item.onclick && item.onclick();navigateTo(item)"
            :value="item.url"
            class="mt-10"
          >
            <v-list-item-action>

              <v-tooltip
                bottom
                open-delay="200"
                :disabled="!menuDrawerIsMini"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-icon>{{item.icon}}</v-icon>
                  </div>
                </template>
                <span>{{$tc(item.title)}}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-title>{{$tc(item.title)}}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-btn
          block
          text
          @click.stop="showReleaseNotes()"
          class="px-0 mb-6"
        >
          <v-tooltip
            bottom
            open-delay="200"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on">

                <v-icon
                  v-bind:class="{ 'pulse-element-loop': hasNewReleaseNotes }"
                  :color="hasNewReleaseNotes ? 'secondary' : ''"
                >{{$icon('i.Help')}}</v-icon>

              </div>
            </template>
            <span>{{$t('t.Help')}}</span>
          </v-tooltip>
        </v-btn>
        <v-btn
          block
          text
          @click.stop="showUISettings = true"
          class="px-0"
        >
          <icon-tooltip
            bottom
            :icon-name="'i.UISettings'"
            :tooltip-text="$t('t.Theme')"
          />
        </v-btn>
        <v-btn
          block
          text
          @click.stop="switchDrawerMode()"
          class="px-0"
        >
          <icon-tooltip
            :class-icon="{'rotate-180': !menuDrawerIsMini}"
            bottom
            :icon-name="'i.ChevronRight'"
            :tooltip-text="menuDrawerIsMini? $t('t.OpenPanel') : $t('t.ReducePanel')"
          />
        </v-btn>
      </template>
    </v-navigation-drawer>
    <v-main>
      <div
        v-bind:style="mainContentStyle"
        class="d-flex"
        :class="{'background-plain': !themeController.backgroundImageUrl}"
      >
        <keep-alive
          :exclude="excludeFromCache"
          max=10
        >
          <router-view
            :key="$route.path"
            v-keep-scroll-position
          />
        </keep-alive>
        <side-panel
          id="side-panel"
          :width="512"
          :reopen-icon="sidePanelController.reopenIcon"
          ref="sidePanel"
        />
      </div>
    </v-main>
    <v-snackbar
      bottom
      :color="snackbar.color"
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      content-class="snackbar-content"
    >
      <div class="d-flex align-center justify-center">
        <v-icon
          v-if="snackbar.icon"
          class="mr-2"
          color="black"
        >{{snackbar.icon}}</v-icon>
        <div class="d-flex align-baseline snackbar-message">
          <span class="message">{{snackbar.message}}</span>
          <v-spacer />
          <v-btn
            v-if="snackbar.callback"
            @click="snackbar.callback"
            text
            class="ml-3"
          >{{snackbar.callbackMessage}}</v-btn>
        </div>
      </div>
    </v-snackbar>
    <v-footer
      :color="themeController.bannerColor"
      :class="[themeController.isFramed ? 'primary' : 'inherit']"
      fixed
    >
      <v-spacer />
      <v-badge
        dot
        offset-x="20"
        offset-y="6"
        color="warning"
        :value="hasNewReleaseNotes"
      >
        <application-info-label class="mr-4" />
      </v-badge>
      <span class="footer-info mr-10">&copy; DIMO Software {{ new Date().getFullYear() }}</span>
    </v-footer>
    <ui-settings :isVisible.sync="showUISettings" />
    <v-dialog
      v-model="hasNewReleaseNotes"
      persistent
      max-width=700
    >
      <v-card>
        <v-img
          style="height: 350px;"
          class="pa-8 pt-10"
          contain
          src="@/assets/new-release.svg"
        />
        <v-card-actions>
          <v-spacer />
          <div class="d-flex flex-column release-popup-action mt-4 mb-2">
            <span class="mb-8 text-h5 primary--text">{{$t('t.ApplicationUpdated')}}</span>
            <v-btn
              color="primary"
              class="mb-4"
              @click.stop="showReleaseNotes()"
            >{{$t('t.ClickToViewReleaseNotes')}}</v-btn>
          </div>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import auditController from '@/auditController'
import sidePanelController from '@/sidePanelController'
import themeController from '@/themeController'

async function reloadIFrame (component) {
  const mod = await import('@/pages/' + component)
  const e = document.getElementsByTagName('iframe')?.[0]
  if (e?.src) { e.src = mod.default.computed.url() }
}

export default {
  name: 'main-layout',
  components: {
    // AccountContactHeader: () => import('../pages/account-contacts/account-contact-header'),
    UserHeader: () => import('../pages/users/user-header'),
    AccountHeader: () => import('../pages/account/account-header'),
    AccountRef: () => import('@/components/documents-ref/account-ref'),
    ApplicationInfoLabel: () => import('@/components/application-info-label'),
    AuditPanel: () => import('@/components/audit-panel'),
    CurrentUser: () => import('@/components/current-user'),
    HistorySidePanel: () => import('@/pages/history/history-side-panel.vue'),
    Mails: () => import('@/components/mails'),
    MainActionButton: () => import('@/components/main-action-button'),
    MainPresets: () => import('@/pages/search/components/preset/main-presets'),
    Notif: () => import('@/components/notif/notif'),
    RecentsMenu: () => import('@/components/recents-menu'),
    SearchField: () => import('@/pages/search/components/search-field.vue'),
    SidePanel: () => import('@/components/side-panel'),
    SystemStatusPanel: () => import('@/components/system-status-panel'),
    TopicSidePanel: () => import('@/pages/topics/topic-side-panel.vue'),
    UiSettings: () => import('../pages/settings/ui-settings.vue'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  created () {
    sidePanelController.hideSidePanel()
    sidePanelController.documentId = 0
  },
  data () {
    const menuItems = [
      { icon: this.$icon('i.Dashboard'), url: '/', title: 't.Dashboard' }
    ]

    if (this.$store.getters.currentUserHasPermission('Reports')) {
      menuItems.push({
        icon: this.$icon('i.Reports'),
        url: '/reports-v5',
        title: 't.Reports',
        onclick: function () {
          reloadIFrame('reports')
        }
      })
    }

    if (this.$store.getters.currentUserHasPermission('GeneralSettings')) {
      menuItems.push({
        icon: this.$icon('i.Settings'),
        url: '/settings',
        title: 't.Settings'
      })
    }

    if (this.$store.getters.currentUserHasPermission('Treatments')) {
      menuItems.push({
        icon: this.$icon('i.Processing'),
        url: '/processing',
        title: 't.Processing'
      })
    }

    if (this.$store.getters.currentUserHasPermission('RunAdminTasks')) {
      menuItems.push({
        icon: this.$icon('i.Administration'),
        url: '/administration',
        title: 't.Administration'
      })
    }

    return {
      showUISettings: false,
      themeController: themeController,
      activeTaskHeight: 92,
      currentMenuItem: null,
      isReleaseNotesRead: true,
      menuDrawerIsMini: true,
      menuDrawer: true,
      menuItems,
      sidePanelController: sidePanelController,
      auditController: auditController
    }
  },
  watch: {
    menuDrawer: function () {
      if (!this.menuDrawer) {
        this.menuDrawer = true
        this.menuDrawerIsMini = true
      }
    }
  },
  computed: {
    snackbar: {
      get () {
        return this.$store.getters.snackbar
      }
    },
    excludeFromCache () {
      return [
        'bulk-action',
        'collaboration-task',
        'credit-insurance',
        'credit-monitoring',
        'dispute',
        'external-risk',
        'incoming-message',
        'promise',
        'purge',
        'risk-category',
        'work-item'
      ]
    },
    logoColor: function () {
      return this.$vuetify.theme.dark ? 'white' : 'gray'
    },
    hasNewReleaseNotes () {
      return this.$store.getters.currentUser?.hasNewReleaseNotes
    },
    appStyle () {
      const r = {
        backgroundImage: `url('${this.themeController.backgroundImageUrl ? this.themeController.backgroundImageUrl : ''}')`
      }

      return r
    },
    currentModule () {
      return this.$store.getters.module
    },
    mainContentStyle () {
      const result = {
        height: `calc(100vh - ${this.activeTaskHeight}px)`
      }
      return result
    }
  },
  methods: {
    showReleaseNotes () {
      const targetCulture = this.$store.getters.currentUser.culture === 'fr-CA' ? 'fr' : 'en'
      const helpdocs = JSON.parse(window.sessionStorage.getItem('helpDocs') ?? '{}')

      if (helpdocs.url) {
        window.open(`${helpdocs.url}/l/${targetCulture}/article/6kwdxjn3aj-nouveaut-produit?hd_jwt=${helpdocs.token}`, '_blank')
      }
      if (this.hasNewReleaseNotes) {
        this.$store.dispatch('setCurrentUser', { hasNewReleaseNotes: false, isNewReleaseNotesRead: true })
      }
    },
    switchDrawerMode () {
      this.menuDrawerIsMini = !this.menuDrawerIsMini
    },
    navigateTo (menuItem) {
      if (this.$route.path === menuItem.url) {
        return
      }
      this.currentMenuItem = menuItem
      this.menuDrawer = !this.menuDrawer
      this.$router.push(menuItem.url).catch(e => console.log(e))
    },
    navigateToTab (tab) {
      this.$account.navigate(tab.parentDocumentId, { tab: tab.documentType })
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> .snackbar-content
  width 100%

.snackbar-message
  color black
  overflow hidden
  text-overflow ellipsis
  width 100%

  .message
    font-size 1.25em

.bg
  width 100%
  height 100%
  position absolute
  top 0
  left 0
  background-size 100% 100%

.reduce
  transform rotate(-180deg)
  transition transform 200ms ease

.app-logo
  width 30px
  height 30px
  margin 0 auto

.headerBtn
  margin-left 10px

.creationModeBtn
  margin-left 50px

nav .v-list-item:not(.v-item--active) .v-icon.theme--light, nav .v-btn > .v-btn__content i.v-icon.theme--light
  color #B2B2B2

.remove-basis
  flex-basis 0%

.full-width
  width 100% !important

.inherit-color
  color inherit !important

.help-docs-popup
  margin-top -16px

.release-popup-action
  text-align center
</style>
